<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('grading_systems')"
                        :isNewButton="checkPermission('gradingsystem_store')"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('grading_systems')"
                              :isNewButton="checkPermission('gradingsystem_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus=$event"
                />
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :isSmallFilter="true"
            >
                <b-row>
                    <b-col cols="12" md="6" lg="8">
                        <b-form-input v-model="datatable.queryParams.filter.name"
                                      :placeholder="$t('name')"
                        />
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <status-selectbox v-model="datatable.queryParams.filter.status"
                                          :placeholder="$t('status')"
                        />
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="formModal"
                         :onHideOnlyX="true"
                         :size="formProcess=='gradingSystem' ? 'xxl':'md'"
                         @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    <span v-if="formProcess=='create'">{{ $t('new').toUpper() }}</span>
                    <span v-if="formProcess=='gradingSystem'">{{ $t('grading_systems').toUpper() }}</span>
                    <span v-if="formProcess=='update'">{{ $t('update').toUpper() }}</span>
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create' && checkPermission('gradingsystem_store')"
                    />
                    <grading-system :gradingSystemId="formId"
                                    @gradingSystemFormSuccess="gradingSystemFormSuccess"
                                    v-if="formProcess=='gradingSystem' && checkPermission('gradingsystem_update')"
                    />
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update' && checkPermission('gradingsystem_update')"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Components
    import CommonModal from '@/components/elements/CommonModal';
    import Datatable from '@/components/datatable/Datatable';
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';

    // Pages
    import CreateForm from './CreateForm';
    import GradingSystem from './Grade/Index.vue';
    import UpdateForm from './UpdateForm';

    // Services
    import GradingSystemService from '@/services/GradingSystemService'

    // Others
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import qs from 'qs'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            CommonModal,
            Datatable,
            DatatableFilter,
            StatusSelectbox,
            ValidationObserver,
            ValidationProvider,

            CreateForm,
            GradingSystem,
            UpdateForm
        },
        metaInfo() {
            return {
                title: this.$t('grading_systems')
            }
        },
        data() {
            return {
                formId: null,
                formProcess: null,

                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('grading_system'),
                                    class: 'ri-list-check ',
                                    permission: 'grade_show',
                                    callback: (row) => {
                                        this.gradingSystemFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    permission: 'gradingsystem_update',
                                    class: 'ri-search-eye-line',
                                    callback: (row) => {
                                        this.updateFormShow(row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    permission: 'gradingsystem_delete',
                                    class: 'ri-delete-bin-7-line',
                                    callback: (row) => {
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            thClass: 'text-center',
                            tdClass: 'width-50 text-center',
                            sortable: false
                        },
                        {
                            label: this.$t('name'),
                            field: 'name',
                            sortable: true
                        },
                        {
                            label: this.$t('status'),
                            field: 'status',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'width-50 p-1 text-center align-middle',
                            formatFn: (value) => {
                                if (value == 'a') {
                                    return '<span class="badge badge-success mb-0">' + this.$t('active') + '</span>'
                                }
                                else {
                                    return '<span class="badge badge-danger mb-0">' + this.$t('passive') + '</span>'
                                }
                            }
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        created() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
                    name: null,
                    status: null
                }
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return GradingSystemService.getAll(config)
                                           .then((response) => {
                                               this.datatable.rows = response.data.data
                                               this.datatable.total = response.data.pagination.total
                                           })
                                           .catch((e) => {
                                               this.showErrors(e)
                                           })
                                           .finally(() => {
                                               this.datatable.isLoading = false;
                                           })
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Grade System
            gradingSystemFormShow(id) {
                this.formId = id
                this.formProcess = 'gradingSystem'
                this.$refs.formModal.$refs.commonModal.show()
            },
            gradingSystemFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Delete
            formDelete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            GradingSystemService.del(id)
                                                .then(response => {
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                    this.getRows();
                                                    this.formClear();
                                                })
                                                .catch(e => {
                                                    this.showErrors(e)
                                                });
                        }
                    })
            }
        }
    }
</script>

